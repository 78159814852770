<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-card
      v-if="!$apollo.loading && programs.length < 1"
      class="text-center"
    >
      No applications found!
    </b-card>
    <section class="grid-view">

      <b-card
        v-for="(program, j) in programs"
        :key="j"
        :title="program.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <div
          class="px-2 py-1 bg-primary"
        >
          <b-card-title class="text-white">
            {{ program.title }}
          </b-card-title>
          <b-card-sub-title>
            <span class="text-light">{{ program.type }}</span>
          </b-card-sub-title>
        </div>
        <b-card-body class="p-2">
          <b-card-text>
            Description: <br> <em>{{ program.description }}</em>
            <hr>
            Begin date: {{ program.begin_date }}
            <br> Capacity: {{ program.capacity }}
            <br> Industry: {{ program.industry }}
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            :to="`/student-portal/applications/rounds/${program.id}`"
            class="btn btn-primary btn-cart"
          >
            <span>Open</span>
          </b-link>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardSubTitle, BCardText, BCardTitle, BLink, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      programs: [],
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  apollo: {
    programs: {
      query() {
        return gql`{
          programs_basicinfo(where: { type: {_in: ["skill-dev"]}, programs_applicantstables: {organization_id_id: {_in: [${this.userOrgs}]}}}) {
            id
            begin_date
            capacity
            description
            image
            industry
            organization_id_id
            title
            total_shortlisting_rounds
            type
          }
        }
      `
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
